<template>
  <div class="page-container">
    <page-header></page-header>
    <div style="padding: 10px;">
      <el-button-group>
        <el-button size="mini"  @click="$router.replace('/admin/service-order')">踏勘工单</el-button>
        <el-button type="primary" size="mini">施工工单</el-button>
      </el-button-group>
    </div>
    <acceptance-list :show-header="false" :order-page="getWorkIngPage"></acceptance-list>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import AcceptanceList from "@/views/constructionAcceptance/List";
import {getWorkIngPage} from "@/api/apis";
export default {
  name: 'Construction',
  components: {
    AcceptanceList,
    PageHeader,
  },
  data(){
    return {
      getWorkIngPage
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
